/** @jsx jsx */
/**
 * Module dependencies
 */
import { jsx, css, Global } from "@emotion/core"
import emotionNormalize from "emotion-normalize"
import { useTheme } from "emotion-theming"

/**
 * Styles
 */
const makeGlobalStyles = theme => css`
  ${emotionNormalize}

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    background: ${theme.colors.background};
    color: ${theme.colors.text};
    min-height: 100%;
    height: 100%;
    font-family: ${theme.miscellaneous.fontFamily};
    font-size: ${theme.fontSizes.base};
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: ${theme.fontWeights.medium};
  }

  h1 {
    font-size: ${theme.fontSizes.xxxl};
  }

  h2 {
    font-size: ${theme.fontSizes.xxl};
  }

  h3 {
    font-size: ${theme.fontSizes.xl};
  }

  h4 {
    font-size: ${theme.fontSizes.l};
  }

  p {
    line-height: 1.3;
    margin: ${theme.gutters.base} 0;
    font-weight: ${theme.fontWeights.light};
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  svg path {
    fill: ${theme.colors.text};
  }

  .message {
    border-radius: 6px;
    padding: ${theme.gutters.base};
    margin: ${theme.gutters.base} 0;
    text-align: left;
  }

  .message--success {
    background-color: #8bc34a;
    color: ${theme.colors.background};
  }

  .message--error {
    background-color: #b22a00;
    color: ${theme.colors.text};
  }

  @media (min-width: 768px) {
    h1 {
      font-size: ${theme.fontSizes.xxxxl};
    }
  }
`

/**
 * GlobalStyles
 */
const GlobalStyles = () => {
  const theme = useTheme()

  return <Global styles={makeGlobalStyles(theme)} />
}

/**
 * Expose module
 */
export default GlobalStyles
