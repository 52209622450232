import React from "react"
import useIntlWrapper from "../../utils/useIntlWrapper"
import { Modal } from "../Modal"

export function ModalPrivacy({ isOpen, onClose }) {
  const t = useIntlWrapper()
  return (
    <Modal
      title={t("Política de privacidad")}
      isOpen={isOpen}
      onClose={onClose}
    >
      <p>{t("privacy.content.2")}</p>
      <h3>{t("privacy.title.3")}</h3>
      <p>{t("privacy.content.3")}</p>
      <h3>{t("privacy.title.4")}</h3>
      <p>{t("privacy.content.4")}</p>
      <p>{t("privacy.content.5")}</p>
      <h3>{t("privacy.title.5")}</h3>

      <p>{t("privacy.content.6")}</p>
      <p>{t("privacy.content.7")}</p>
      <h3>{t("privacy.title.6")}</h3>

      <p>{t("privacy.content.8")}</p>
      <h3>{t("privacy.title.7")}</h3>

      <p>{t("privacy.content.9")}</p>
      <p>{t("privacy.content.10")}</p>
      <p>{t("privacy.content.11")}</p>
    </Modal>
  )
}
