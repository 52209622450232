/**
 * Module dependencies
 */
import React, { useMemo, useState } from "react"
import styled from "@emotion/styled"
import { rgba } from "emotion-rgba"
import { Link } from "gatsby"
import Container from "../Container"
import Logo from "../Logo"
import { Instagram, Facebook } from "../Networks"
import useMenuLinks from "../../hooks/useMenuLinks"
import useLocale from "../../hooks/useLocale"
import { ModalPrivacy } from "../ModalPrivacy/ModalPrivacy"
import useIntlWrapper from "../../utils/useIntlWrapper"
import { ModalTerms } from "../ModalTerms"

const FooterStyled = styled.footer`
  background-color: ${({ theme }) => rgba(theme.colors.text, 0.05)};
  padding: ${({ theme }) => theme.gutters.l} 0 0 0;
`

const CopyRightStyled = styled.div`
  background-color: ${({ theme }) => rgba(theme.colors.text, 0.04)};
  padding: ${({ theme }) => theme.gutters.base} 0;
  margin-top: ${({ theme }) => theme.gutters.l};
  text-align: center;

  @media (min-width: 768px) {
    text-align: right;
  }
`

const FollowLabelStyled = styled.span`
  color: ${({ theme }) => theme.colors.text};
  display: block;
  margin-bottom: ${({ theme }) => theme.gutters.s};

  @media (min-width: 768px) {
    display: inline-block;
    margin-right: ${({ theme }) => theme.gutters.s};
  }
`

const NetsContainerStyled = styled.div`
  text-align: center;
  margin: ${({ theme }) => theme.gutters.m} 0;

  a {
    display: inline-block;
    margin-right: ${({ theme }) => theme.gutters.s};
  }

  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: ${({ theme }) => theme.gutters.s};
    margin-bottom: 0;
  }
`

const NavStyled = styled.nav`
  width: 100%;
  height: 100%;
  margin: ${({ theme }) => theme.gutters.xl} 0;

  @media (min-width: 768px) {
    position: relative;
    display: flex;
    flex: 0.7;
    margin: 0;
  }
`

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media (min-width: 768px) {
    display: flex;
  }
`

const LinkStyled = styled(Link)`
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.l};

  @media (min-width: 768px) {
    padding: 0 ${({ theme }) => theme.gutters.base};
  }
`

const LiStyled = styled.li`
  position: relative;
  margin-bottom: ${({ theme }) => theme.gutters.s};
  ${({ theme, isActive }) =>
    `
    &:after {
      content: '';
      display:block;
      height: 4px;
      background-color: ${theme.colors.primary};
      border-radius: 6px;
      position: absolute;
      transition: opacity .15s ease-out;
      left: ${theme.gutters.s};
      right: ${theme.gutters.s};
      opacity: ${isActive ? 1 : 0};
      bottom: 0;
      max-width: 75px;
      margin: auto;
    }
  `}
  @media (min-width: 768px) {
    padding-bottom: ${({ theme }) => theme.gutters.base};
    margin-bottom: 0;

    &:focus:after,
    &:hover:after {
      opacity: 1;
    }
  }
`

const FlexStyled = styled.div`
  display: block;

  a:first-of-type {
    text-align: center;
  }
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const FooterLink = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
`

const AddressText = styled.p`
  text-align: center;
  margin: 0;
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.xs};

  &:first-of-type {
    margin-top: ${({ theme }) => theme.gutters.base};
  }

  @media (min-width: 768px) {
    text-align: left;
  }
`

const LogoAndAddressContainer = styled.div`
  @media (min-width: 768px) {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`
/**
 * Footer
 */
const Footer = () => {
  const t = useIntlWrapper()
  const [isPrivacyOpen, setPrivacyOpen] = useState(false)
  const [isTermsOpen, setTermsOpen] = useState(false)
  const locale = useLocale()
  const links = useMenuLinks()
  const isArg = useMemo(() => {
    return (
      typeof window !== "undefined" &&
      window?.location.href.includes("wetalent.com.ar")
    )
  }, [])

  const address = isArg
    ? "José Esteban Bustos 1795, Ofi. 301, 5009, Córdoba"
    : "530-B Harkle Road STE 100, Santa Fe, NM, 87505"

  return (
    <>
      <FooterStyled>
        <Container>
          <FlexStyled>
            <LogoAndAddressContainer>
              <Logo />
              <AddressText>Wetalent {!isArg && "LLC"}</AddressText>
              <AddressText>{address}</AddressText>
              {!isArg && <AddressText>+1 (970) 400-9655</AddressText>}
            </LogoAndAddressContainer>
            <div>
              <NavStyled>
                <ListStyled>
                  {links.map(link => (
                    <LiStyled key={link.id}>
                      <LinkStyled to={link.path}>
                        {link.label[locale]}
                      </LinkStyled>
                    </LiStyled>
                  ))}
                </ListStyled>
              </NavStyled>
              <NetsContainerStyled>
                <FollowLabelStyled>{t("Síguenos en")}</FollowLabelStyled>
                <Facebook width={28} height={28} />
                <Instagram width={24} height={24} />
              </NetsContainerStyled>
            </div>
          </FlexStyled>
        </Container>
        <CopyRightStyled>
          <Container>
            <FlexStyled justifyContent="space-between">
              <div>
                <FooterLink onClick={() => setTermsOpen(true)}>
                  {t("Términos y condiciones")}
                </FooterLink>
                <span> | </span>
                <FooterLink onClick={() => setPrivacyOpen(true)}>
                  {t("Política de privacidad")}
                </FooterLink>
              </div>
              <div>
                Wetalent {!isArg && "LLC"} © {new Date().getFullYear()}
              </div>
            </FlexStyled>
          </Container>
        </CopyRightStyled>
      </FooterStyled>
      <ModalPrivacy
        isOpen={isPrivacyOpen}
        onClose={() => setPrivacyOpen(false)}
      />
      <ModalTerms isOpen={isTermsOpen} onClose={() => setTermsOpen(false)} />
    </>
  )
}

/**
 * Expose module
 */
export default Footer
