/** @jsxFrag React.Fragment */
/**
 * Module dependencies
 */
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { FormattedMessage, injectIntl, changeLocale } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { TranslateIcon, MenuIcon } from "../Icons"
import Tooltip from "../Tooltip"
import useLocale from "../../hooks/useLocale"
import Card from "../Card"

const NavStyled = styled.nav`
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color 0.1s ease-in;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 98px;
  z-index: 2;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transform: translateX(${({ open }) => (open ? "0%" : "100%")});
  transition: transform 0.5s ease-in-out, opacity 0.7s ease-in-out;
  justify-content: flex-end;

  @media (min-width: 768px) {
    position: relative;
    display: flex;
    flex: 0.7;
    top: 0;
    opacity: 1;
    left: initial;
    transform: initial;
  }
`

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media (min-width: 768px) {
    display: flex;
  }
`

const LinkStyled = styled(Link)`
  display: block;
  padding: ${({ theme }) => theme.gutters.s} 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.xl};

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.l};
    padding: 0 ${({ theme }) => theme.gutters.base};
  }
`

const LiStyled = styled.li`
  position: relative;
  margin-bottom: ${({ theme }) => theme.gutters.base};
  ${({ theme, isActive }) =>
    `
    &:after {
      content: '';
      display:block;
      height: 4px;
      background-color: ${theme.colors.primary};
      border-radius: 6px;
      position: absolute;
      transition: opacity .15s ease-out;
      left: ${theme.gutters.s};
      right: ${theme.gutters.s};
      opacity: ${isActive ? 1 : 0};
      bottom: 0;
      max-width: 75px;
      margin: auto;
    }
  `}
  @media (min-width: 768px) {
    padding-bottom: ${({ theme }) => theme.gutters.base};
    margin-bottom: 0;

    &:focus:after,
    &:hover:after {
      opacity: 1;
    }
  }
`

const SubListStyled = styled(ListStyled)`
  display: flex;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.gutters.m};
  position: relative;
  top: ${({ theme }) => theme.gutters.l};
  width: 100%;

  @media (min-width: 768px) {
    position: relative;
    justify-content: initial;
    width: auto;
    bottom: initial;
    top: initial;
  }
`

const ButtonOptionStyled = styled.button`
  background: transparent;
  border: 0;
  padding: 0 ${({ theme }) => theme.gutters.s};
  outline: 0;

  @media (min-width: 768px) {
    position: relative;
  }
`

const MenuContainerStyled = styled.div`
  button {
    background: transparent;
    border: 0;
    outline: 0;
  }
  @media (min-width: 768px) {
    display: none;
  }
`

const CardDropdownStyled = styled(Card)`
  position: absolute;
  left: 0;
  right: 0;
  background-color: #212121;
  width: 100%;
  margin-top: 16px;

  &:after {
    display: none;
  }

  @media (min-width: 768px) {
    width: 130px;
    margin: 0 auto;
    transform: translateX(-46px);
    margin-top: 0;

    &:after {
      display: block;
    }
  }

  button {
    background: transparent;
    border: 0;
    color: white;
    display: block;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    padding: 4px;
  }
`

/**
 * Menu
 */
const Menu = ({ links, changeMode }) => {
  const locale = useLocale()
  const [isOpen, toggleMenu] = useState(false)
  const [isDropdownShown, showDropdown] = useState(false)
  const [current, setCurrent] = useState(null)
  const handlerCurrent = (props, link) => {
    if (props.location.pathname.split("/")[2] === link.path.substr(1)) {
      setCurrent(link.id)
    }
  }

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "initial"
  }, [isOpen])

  return (
    <>
      <MenuContainerStyled>
        <button type="button" onClick={() => toggleMenu(!isOpen)}>
          <MenuIcon width={40} height={40} />
        </button>
      </MenuContainerStyled>
      <NavStyled open={isOpen}>
        <ListStyled>
          {links.map(link => (
            <LiStyled key={link.id} isActive={current === link.id}>
              <LinkStyled
                to={link.path}
                getProps={props => handlerCurrent(props, link)}
                aria-current={current === link.id ? "page" : "false"}
              >
                {link.label[locale]}
              </LinkStyled>
            </LiStyled>
          ))}
        </ListStyled>
        <SubListStyled>
          {/* <li>
            <Tooltip pos="bottom" alt={<FormattedMessage id="Modo oscuro" />}>
              <ButtonOptionStyled onClick={changeMode}>
                <BrightnessIcon width={22} height={22} />
              </ButtonOptionStyled>
            </Tooltip>
          </li> */}
          <li>
            <Tooltip pos="bottom" alt={<FormattedMessage id="Traducir" />}>
              <ButtonOptionStyled
                onClick={() => showDropdown(!isDropdownShown)}
              >
                <TranslateIcon width={22} height={22} />
                {isDropdownShown && (
                  <CardDropdownStyled
                    elevation={0}
                    triangle
                    trianglePosition="top-center"
                  >
                    <button
                      onClick={() => {
                        changeLocale("en")
                        showDropdown(false)
                      }}
                    >
                      English
                    </button>
                    <button
                      onClick={() => {
                        changeLocale("es")
                        showDropdown(false)
                      }}
                    >
                      Español
                    </button>
                    <button
                      onClick={() => {
                        changeLocale("pt")
                        showDropdown(false)
                      }}
                    >
                      Português
                    </button>
                  </CardDropdownStyled>
                )}
              </ButtonOptionStyled>
            </Tooltip>
          </li>
        </SubListStyled>
      </NavStyled>
    </>
  )
}

/**
 * Expose module
 */
export default injectIntl(Menu)
