/** @jsx jsx */
/**
 * Module dependencies
 */
import { jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"

/**
 * Container
 */
const Container = ({ children, className }) => {
  const theme = useTheme()
  return (
    <div
      css={() => ({ maxWidth: theme.miscellaneous.maxWidth, margin: "0 auto" })}
      className={className}
    >
      {children}
    </div>
  )
}

/**
 * Expose module
 */
export default Container
