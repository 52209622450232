/** @jsx jsx */
/**
 * Module dependencies
 */
import "react"
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { ReactComponent as LogoSvg } from "./isologo.svg"

const LinkStyled = styled(Link)`
  display: block;
`

/**
 * Logo
 */
const Logo = () => {
  return (
    <LinkStyled to="/">
      <LogoSvg width={200} height={63} aria-labelledby="Wetalent" role="logo" />
    </LinkStyled>
  )
}

/**
 * Expose module
 */
export default Logo
