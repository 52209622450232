/** @jsx jsx */
/**
 * Module dependencies
 */
import React from "react"
import PropTypes from "prop-types"
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import useMeasure from "react-use-measure"
import { ResizeObserver } from "@juggle/resize-observer"
import Card from "../Card"

const OFFSET_TOOLTIP = 16

const CardStyled = styled(Card)`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.s};
  padding-top: ${({ theme }) => theme.gutters.s};
  padding-bottom: ${({ theme }) => theme.gutters.s};
  position: fixed;
  top: ${({ bounds: [boundsCard, boundsContent], pos }) =>
    pos === "top"
      ? boundsContent.y - boundsContent.height - OFFSET_TOOLTIP
      : boundsContent.y + boundsContent.height + OFFSET_TOOLTIP}px;
  left: ${({ bounds: [boundsCard, boundsContent] }) =>
    (boundsContent.width - boundsCard.width) / 2 + boundsContent.x}px;
  max-width: 220px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(${({ pos }) => (pos === "top" ? 10 : -10)}px);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
`

const TooltipStyled = styled.div`
  display: inline-block;
  &:hover ${CardStyled} {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
`

const TooltipContentStyled = styled.span`
  display: inline-block;
`

/**
 * Tooltip
 */
const Tooltip = ({ alt, children, pos }) => {
  const trianglePosition = pos === "top" ? "bottom-center" : "top-center"
  const [refCard, boundsCard] = useMeasure({
    polyfill: ResizeObserver,
    scroll: true,
  })
  const [refContent, boundsContent] = useMeasure({
    polyfill: ResizeObserver,
    scroll: true,
  })
  return (
    <TooltipStyled>
      <CardStyled
        triangle
        trianglePosition={trianglePosition}
        elevation={0}
        bounds={[boundsCard, boundsContent]}
        pos={pos}
        ref={refCard}
      >
        {alt}
      </CardStyled>
      <TooltipContentStyled ref={refContent}>{children}</TooltipContentStyled>
    </TooltipStyled>
  )
}

Tooltip.propTypes = {
  pos: PropTypes.oneOf(["top", "bottom"]),
}

Tooltip.defaultProps = {
  pos: "top",
}

/**
 * Expose module
 */
export default Tooltip
