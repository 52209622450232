/** @jsx jsx */
/**
 * Module dependencies
 */
import "react"
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { FacebookIcon, InstagramIcon, LinkedinIcon } from "../Icons"

/**
 * Facebook
 */
export const Facebook = ({ width, height }) => {
  const theme = useTheme()
  return (
    <a
      href="https://www.facebook.com/wetalentar/"
      target="_blank"
      rel="noopener noreferrer"
      title="Facebook"
      css={css`
        svg path {
          fill: ${theme.colors.text};
        }
      `}
    >
      <FacebookIcon width={width} height={height} />
    </a>
  )
}

/**
 * Instagram
 */
export const Instagram = ({ width, height }) => {
  const theme = useTheme()
  return (
    <a
      href="https://www.instagram.com/wetalentar/"
      target="_blank"
      rel="noopener noreferrer"
      title="Instagram"
      css={css`
        svg path {
          fill: ${theme.colors.text};
        }
      `}
    >
      <InstagramIcon width={width} height={height} />
    </a>
  )
}

/**
 * Linkedin
 */
export const Linkedin = ({ width, height }) => {
  const theme = useTheme()
  return (
    <a
      href="https://www.linkedin.com/company/we-talent/?originalSubdomain=ar"
      target="_blank"
      rel="noopener noreferrer"
      title="Linkedin"
      css={css`
        svg path {
          fill: ${theme.colors.text};
        }
      `}
    >
      <LinkedinIcon width={width} height={height} />
    </a>
  )
}
