/**
 * Module dependencies
 */
import { IntlContextConsumer } from "gatsby-plugin-intl"
import { useContext } from "react"

const useLocale = () => {
  const { language } = useContext(IntlContextConsumer)
  return language
}

/**
 * Expose module
 */
export default useLocale
