/**
 *
 * Module dependencies
 */
import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import GlobalStyles from "../GlobalStyles"
import Header from "../Header"
import Footer from "../Footer"
import { ThemeManagerContext } from "../../utils/Theme"
import { CookieConsent } from "../CookieConsent"

/**
 * Layout
 */
const Layout = ({ children }) => {
  const theme = useContext(ThemeManagerContext)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wetalent</title>
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        <script src="/js/aws.js" defer></script>
      </Helmet>
      <GlobalStyles />
      <Header changeMode={() => theme.toggleDark()} />
      <section>{children}</section>
      <Footer />
      <CookieConsent />
    </>
  )
}

/**
 * Expose module
 */
export default Layout
