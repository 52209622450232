/**
 * Module dependencies
 */
import "react"
import { useIntl } from "gatsby-plugin-intl"

/**
 * useIntlWrapper
 */
const useIntlWrapper = () => {
  const intl = useIntl()
  const t = message => intl.formatMessage({ id: message })
  return t
}

/**
 * Expose module
 */
export default useIntlWrapper
