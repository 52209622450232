import styled from "@emotion/styled"
import { rgba } from "emotion-rgba"
import React, { useEffect } from "react"
import Flex from "../Flex"
import { CloseIcon } from "../Icons"

const ModalWrapper = styled.section`
  background: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.gutters.l};
  width: 100%;
  max-width: 600px;
  position: fixed;
  z-index: 101;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${({ theme }) => theme.miscellaneous.borderRadius};
  box-shadow: 0 0 16px 4px ${() => rgba("#000", 0.3)};

  @media (min-width: 768px) {
    height: 80%;
  }
`
const ModalOverlay = styled.section`
  background: ${() => rgba("#000", 0.5)};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`

const ModalCloseButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

const ModalContent = styled.div`
  box-sizing: border-box;
  overflow: auto;
  height: 90%;
  padding: 0 ${({ theme }) => theme.gutters.l} ${({ theme }) => theme.gutters.l}
    ${({ theme }) => theme.gutters.base};
  margin-top: ${({ theme }) => theme.gutters.base};
`

const ModalTitle = styled.h1`
  margin: 0;
  padding-left: ${({ theme }) => theme.gutters.base};
  font-size: ${({ theme }) => theme.fontSizes.xxxl};
`

export function Modal({ children, isOpen, title, onClose }) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto"
  }, [isOpen])

  if (!isOpen) return null

  return (
    <>
      <ModalOverlay onClick={onClose} />
      <ModalWrapper>
        <Flex justifyContent="space-between" alignItems="center">
          <ModalTitle>{title}</ModalTitle>
          <ModalCloseButton onClick={onClose}>
            <CloseIcon />
          </ModalCloseButton>
        </Flex>
        <ModalContent>{children}</ModalContent>
      </ModalWrapper>
    </>
  )
}
