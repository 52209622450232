/** @jsx jsx */
/**
 * Module dependencies
 */
import React from "react"
import { jsx } from "@emotion/core"
import { rgba } from "emotion-rgba"
import styled from "@emotion/styled"

const getAlpha = elevation => {
  switch (elevation) {
    case 0:
      return 0.05
    case 1:
      return 0.08
    case 2:
      return 0.1
    case 3:
      return 0.12
    default:
      return 1
  }
}

const triangleTop = (elevation, baseColor) => `
  border-width: 0 10px 13px 10px;
  border-color: transparent transparent ${rgba(
    baseColor,
    getAlpha(elevation)
  )} transparent;
`

const triangleRight = (elevation, baseColor) => `
  border-width: 10px 0 10px 13px;
  border-color: transparent transparent transparent ${rgba(
    baseColor,
    getAlpha(elevation)
  )};
`

const triangleBottom = (elevation, baseColor) => `
  border-width: 13px 10px 0 10px;
  border-color: ${rgba(
    baseColor,
    getAlpha(elevation)
  )} transparent transparent transparent;  
`

const triangleLeft = (elevation, baseColor) => `
  border-width: 10px 13px 10px 0;
  border-color: transparent ${rgba(
    baseColor,
    getAlpha(elevation)
  )} transparent transparent;
`

const calculateTrianglePosition = (elevation, baseColor, trianglePosition) => {
  const [x, y] = trianglePosition.split("-")
  const isTopOrBottom = ["top", "bottom"].includes(x)
  const isLeftOrRight = ["left", "right"].includes(x)

  let triangle
  const size = `
    height: ${isTopOrBottom ? 13 : 10}px;
    width: ${isLeftOrRight ? 13 : 10}px;
  `
  const position = `
    top: ${
      (x === "top" && `-13px`) ||
      (x === "bottom" && "initial") ||
      (isLeftOrRight && y === "top" && "16px") ||
      (isLeftOrRight && y === "bottom" && "initial") ||
      (isLeftOrRight && y === "center" && "0px")
    };
    left: ${
      (isTopOrBottom && y === "center" && "0") ||
      (isTopOrBottom && y === "left" && "16px") ||
      (isTopOrBottom && y === "right" && "initial") ||
      (isLeftOrRight && x === "left" && "-13px") ||
      (isLeftOrRight && x === "right" && "initial")
    };
    right: ${
      (isTopOrBottom && y === "center" && "0") ||
      (isTopOrBottom && y === "right" && "16px") ||
      (isTopOrBottom && y === "left" && "initial") ||
      (isLeftOrRight && x === "right" && "-13px") ||
      (isLeftOrRight && x === "left" && "initial")
    };
    bottom: ${
      (x === "bottom" && `-13px`) ||
      (x === "top" && "initial") ||
      (isLeftOrRight && y === "top" && "initial") ||
      (isLeftOrRight && y === "bottom" && "16px") ||
      (isLeftOrRight && y === "center" && "0px")
    };
  `

  switch (x) {
    case "top":
      triangle = triangleTop(elevation, baseColor)
      break
    case "right":
      triangle = triangleRight(elevation, baseColor)
      break
    case "bottom":
      triangle = triangleBottom(elevation, baseColor)
      break
    default:
      triangle = triangleLeft(elevation, baseColor)
      break
  }

  return `
    border-style: solid;
    margin: auto;
    ${triangle}
    ${size}
    ${position}
  `
}

const CardStyled = styled.div`
  border-radius: ${({ theme }) => theme.miscellaneous.borderRadius};
  background-color: ${({ elevation, theme }) =>
    rgba(theme.colors.text, getAlpha(elevation))};
  padding: ${({ theme }) => theme.gutters.base};
  overflow: ${({ triangle }) => !triangle && `hidden`};
  position: relative;
  ${({ triangle, elevation, theme, trianglePosition }) =>
    triangle &&
    `&:after {
    content: "";
    display: block;
    position: absolute;
    ${calculateTrianglePosition(elevation, theme.colors.text, trianglePosition)}
  }`}
`

/**
 * Card
 */
const Card = React.forwardRef(
  (
    { children, elevation, triangle, trianglePosition, className, ...rest },
    ref
  ) => {
    return (
      <CardStyled
        elevation={elevation}
        triangle={triangle}
        trianglePosition={trianglePosition}
        className={className}
        ref={ref}
        {...rest}
      >
        {children}
      </CardStyled>
    )
  }
)

/**
 * Expose module
 */
export default Card
