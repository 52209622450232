/** @jsx jsx */
/**
 * Module dependencies
 */
import { jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import PropTypes from 'prop-types';

/**
 * Flex
 */
const Flex = ({ children, directions, justifyContent, alignItems}) => {
  
  const theme = useTheme()
  
  return (
    <div
      css={() => ({ 
        maxWidth: theme.miscellaneous.maxWidth,         
        display: 'flex',
        flexDirection: directions,
        justifyContent: justifyContent,    
        alignItems: alignItems,
      })}
    >
      {children}
    </div>
  )
}

Flex.propTypes = {
  flexDirection: PropTypes.string.isRequired,
  justifyContent: PropTypes.string.isRequired,
  alignItems: PropTypes.string.isRequired,
};

/**
 * Expose module
 */
export default Flex
