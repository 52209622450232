/** @jsx jsx */
/**
 * Module dependencies
 */
import "react"
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import Menu from "../Menu"
import Container from "../Container"
import Logo from "../Logo"
import Flex from "../Flex"
import useMenuLinks from "../../hooks/useMenuLinks"

const HeaderStyled = styled.header`
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.gutters.base} 0;
  padding-left: ${({ theme }) => theme.gutters.base};
  position: sticky;
  top: 0;
  z-index: 10;

  @media (min-width: 768px) {
    padding-left: 0;
  }
`

/**
 * Header
 */
const Header = ({ changeMode }) => {
  const links = useMenuLinks()
  return (
    <HeaderStyled>
      <Container>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Logo />
          <Menu links={links} changeMode={changeMode} />
        </Flex>
      </Container>
    </HeaderStyled>
  )
}

/**
 * Expose module
 */
export default Header
