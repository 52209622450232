/**
 * Module dependencies
 */
import "react"
import { useStaticQuery, graphql } from "gatsby"

const useMenuLinks = () => {
  const {
    allMenuJson: { nodes },
  } = useStaticQuery(graphql`
    {
      allMenuJson {
        nodes {
          id
          label {
            en
            es
            pt
          }
          path
        }
      }
    }
  `)

  return nodes
}

/**
 * Expose module
 */
export default useMenuLinks
