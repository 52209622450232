import React from "react"
import useIntlWrapper from "../../utils/useIntlWrapper"
import { Modal } from "../Modal"

export function ModalTerms({ isOpen, onClose }) {
  const t = useIntlWrapper()
  return (
    <Modal
      title={t("Términos y condiciones")}
      isOpen={isOpen}
      onClose={onClose}
    >
      <p>{t("terms.content.2")}</p>

      <h3>{t("terms.title.3")}</h3>
      <p>{t("terms.content.3")}</p>

      <h3>{t("terms.title.4")}</h3>
      <p>{t("terms.content.4")}</p>
      <p>{t("terms.content.5")}</p>
      <p>{t("terms.content.6")}</p>
      <p>{t("terms.content.7")}</p>

      <h3>{t("terms.title.5")}</h3>
      <p>{t("terms.content.8")}</p>
      <p>{t("terms.content.9")}</p>
    </Modal>
  )
}
