import React, { useCallback, useState } from "react"
import { useLocation } from "@reach/router"
import styled from "@emotion/styled"
import { rgba } from "emotion-rgba"
import Flex from "../Flex"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import Cookies from "js-cookie"

const CookieConsentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  position: fixed;
  padding: ${({ theme }) => theme.gutters.base}
    ${({ theme }) => theme.gutters.l};
  border-radius: ${({ theme }) => theme.miscellaneous.borderRadius};
  z-index: 90;
  bottom: 0;
  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  @media (min-width: 768px) {
    right: ${({ theme }) => theme.gutters.xl};
    bottom: ${({ theme }) => theme.gutters.xl};
    width: 400px;
    border-bottom-right-radius: ${({ theme }) =>
      theme.miscellaneous.borderRadius};
    border-bottom-left-radius: ${({ theme }) =>
      theme.miscellaneous.borderRadius};
  }
`

const CookieConsentTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.l};
  margin: 0;
  margin-top: ${({ theme }) => theme.gutters.s};
`

const CookieConsentButton = styled.button`
  margin: 0;
  background-color: ${({ theme }) => rgba(theme.colors.background, 0.1)};
  color: ${({ theme }) => theme.colors.text};
  border: 0;
  border-radius: ${({ theme }) => theme.miscellaneous.borderRadius};
  padding: ${({ theme }) => theme.gutters.s}
    ${({ theme }) => theme.gutters.base};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  cursor: pointer;

  &:first-of-type {
    margin-right: ${({ theme }) => theme.gutters.s};
  }
`

// const CookieConsentIcon = styled.span`
//   display: inline-block;
//   font-size: ${({ theme }) => theme.fontSizes.xxxxl};
//   transform: scale3d(1.5, 1.5, 1.5) translateY(2px);
//   margin-right: ${({ theme }) => theme.gutters.base};
// `

const cookieName = "gatsby-gdpr-google-analytics"
export const getCookieConsentValue = () => {
  const cookieValue = Cookies.get(cookieName)

  return cookieValue
}

export const resetCookieConsentValue = () => {
  Cookies.remove(cookieName)
}

export function CookieConsent() {
  const rerender = useState(false)[1]
  const location = useLocation()

  const handleAccept = useCallback(() => {
    initializeAndTrack(location)
    Cookies.set(cookieName, true)
    rerender()
  }, [location, rerender])

  const handleReject = useCallback(() => {
    Cookies.set(cookieName, false)
    rerender()
  }, [rerender])

  if (getCookieConsentValue() !== undefined) return null

  return (
    <CookieConsentWrapper>
      <CookieConsentTitle>
        {/* <CookieConsentIcon>🍪</CookieConsentIcon> */}
        Cookie consent
      </CookieConsentTitle>
      <p>This website uses cookies to enhance user experience</p>
      <Flex justifyContent="flex-end">
        <CookieConsentButton size="small" onClick={handleReject}>
          Reject
        </CookieConsentButton>
        <CookieConsentButton size="small" onClick={handleAccept}>
          Accept
        </CookieConsentButton>
      </Flex>
    </CookieConsentWrapper>
  )
}
